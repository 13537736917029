import { Environment } from './environment.type';

export const environment: Environment = {
    LOGIN_URL: 'https://sandbox-login.bannerflow.com',
    CLIENT_ID: 'xbWtqxT3Zo6Bo9tLSFcF2lehpTOnfcB5',
    API_URL: 'https://sandbox-am.bannerflow.com',
    BAU_URL: 'https://sandbox-api.bannerflow.com/bau',
    AC_URL: 'https://sandbox-api.bannerflow.com/account-cleanup',
    HOME_URL: 'https://sandbox-home.bannerflow.com',
    NAME: 'sandbox',

    NEW_RELIC_ACCOUNT_ID: '4232543',
    NEW_RELIC_APPLICATION_ID: '538568842',
    NEW_RELIC_LICENSE_KEY: 'NRJS-d0b27a9b958bc4b281c',
    NEW_RELIC_TRUST_KEY: '4122654',
    RELEASE_NAME: '',
    VERSION: ''
};
