import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { provideAuth0 } from '@auth0/auth0-angular';
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { UpgradeModule } from '@angular/upgrade/static';
import { provideSentinelService, withLoggerConfig, withNewRelic } from '@bannerflow/sentinel';

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(routes),
        provideAuth0({
            domain: environment.LOGIN_URL,
            clientId: environment.CLIENT_ID,
            authorizationParams: {
                redirect_uri: `${window.location.origin}/${window.location.search}`,
                audience: 'https://bannerflow.com/resources/',
                scope: 'openid profile email offline_access bannerflow'
            },
            httpInterceptor: {
                allowedList: [`${environment.API_URL}/*`, `${environment.BAU_URL}/*`]
            },
            useRefreshTokens: true
        }),
        provideHttpClient(withInterceptorsFromDi()), // HttpClient
        provideAnimationsAsync(), // Needed for @bannerflow/ui
        importProvidersFrom(UpgradeModule),
        provideSentinelService(
            withNewRelic({
                enabled: environment.NAME !== 'local',
                licenseKey: environment.NEW_RELIC_LICENSE_KEY,
                applicationId: environment.NEW_RELIC_APPLICATION_ID,
                accountId: environment.NEW_RELIC_ACCOUNT_ID,
                trustKey: environment.NEW_RELIC_TRUST_KEY,
                agentId: environment.NEW_RELIC_APPLICATION_ID,
                origins: [environment.API_URL, environment.BAU_URL],
                applicationVersion: environment.VERSION,
                releaseName: environment.RELEASE_NAME,
                releaseId: environment.VERSION
            }),
            withLoggerConfig({
                level: 'debug',
                production: environment.NAME === 'production',
                sandbox: environment.NAME === 'sandbox',
                stage: environment.NAME
            })
        )
    ]
};
