import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { UpgradeModule } from '@angular/upgrade/static';

import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, from, Observable, of, shareReplay, switchMap } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from './environments/environment';
import { SentinelService } from '@bannerflow/sentinel';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class LocalAuthService {
    private sentinel: SentinelService = inject(SentinelService);

    private tokenSubject = new BehaviorSubject<string | null>(null);
    token$ = this.tokenSubject.asObservable().pipe(shareReplay(1));

    constructor(private auth0Service: AuthService) {
        this.initializeToken();
    }

    private initializeToken(): void {
        this.auth0Service.isAuthenticated$
            .pipe(
                switchMap(isAuthenticated => {
                    if (isAuthenticated) {
                        return this.loadToken();
                    }
                    return of(null);
                })
            )
            .subscribe();
    }

    loadToken(): Observable<string | null> {
        return from(this.auth0Service.getAccessTokenSilently()).pipe(
            catchError(error => {
                this.sentinel.error('Error getting access token:', error);
                return of(null);
            }),
            switchMap(token => {
                this.tokenSubject.next(token);
                return of(token);
            })
        );
    }

    getToken(): Observable<string | null> {
        return this.token$;
    }
}

interface ViewData {
    bannerFlowUrl: string;
    segments: string[];
    roles: string[];
    industries: string[];
    salesRepresentatives: string[];
    customerSuccessManagers: Map<string, string>;
}

bootstrapApplication(AppComponent, appConfig)
    .then(appRef => {
        let bootstrap = false;
        const upgrade = appRef.injector.get(UpgradeModule);
        const authService = appRef.injector.get(LocalAuthService);

        const http = appRef.injector.get(HttpClient);

        authService.getToken().subscribe(token => {
            if (token) {
                (window as any)['token'] = token;
                (window as any)['api_url'] = environment.API_URL;
                (window as any)['bau_url'] = environment.BAU_URL;
                (window as any)['homeUrl'] = environment.HOME_URL;
                if (!bootstrap) {
                    bootstrap = true;
                    http.get<ViewData>(`${environment.BAU_URL}/api/adminpanel/bootstrap`, {
                        headers: { Authorization: 'Bearer ' + token }
                    }).subscribe(data => {
                        (window as any)['viewData'] = data;
                        upgrade.bootstrap(document.body, ['AccountManagerApp'], { strictDi: false });
                    });
                }
            }
        });
    })
    .catch(() => {
        // ignore
    });
